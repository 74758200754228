import { IOfficeholder } from "@boulevard1/mystake-api-sdk";
import {
  IFundraiseConfig,
  IInvestorsListData,
  ILiquidiseConfig,
  INotesCertificateConfig,
  IShareCertificateConfig,
  IUserCompanyRoles,
  IUserRolesByCompany,
  Roles,
  ShareholderStatus,
  getMockUserCompanyRoles,
} from "@boulevard1/mystake-common-sdk";
import { useSnackbar } from "notistack";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAuthContext } from "../../auth/AuthProvider";
import {
  DEFAULT_PAGE_SIZE,
  DEFAULT_SORT_ORDER,
  Order,
  Tier,
  defaultNotesCertificateConfig,
  defaultShareCertificateConfig,
} from "../../constants/constants";
import { companyService, liquidiseService } from "../../util";
import { errorMessage } from "../commons/errorHandler";
import { getBaseServerUrl, initialiseSocket } from "../utility/hooks/useSocketClient";
import { mergeOfficeHolders } from "../utility/officeholders";

interface CompanyContextType {
  companyId: string;
  companyName: string;
  setCompanyName: (e: string) => void;
  acn: string;
  setAcn: (e: string) => void;
  entityType: string;
  setEntityType: (e: string) => void;
  trusteeType: string;
  setTrusteeType: (e: string) => void;
  isAsicConnectionActivated: boolean;
  setAsicConnectionActivated: (e: boolean) => void;
  isLoadingCompanyInfo: boolean;
  setIsLoadingCompanyInfo: (e: boolean) => void;
  selectedSubscriptionPlan: string;
  setSelectedSubscriptionPlan: (planName: string) => void;
  selectedTier: Tier;
  setSelectedTier: (tierName: Tier) => void;
  displayName: string;
  setDisplayName: (name: string) => void;
  displayLogo: string;
  setDisplayLogo: (url: string) => void;
  refreshCompanyData: (newCompanyId?: string) => void;
  loadCompanyInfo: () => Promise<void>;
  companyType: string;
  activeShareholders: number;
  setActiveShareholders: (count: number) => void;
  features: { [key: string]: boolean };
  dateOfRegistration: Date | null;
  isAgentAuthorized: boolean;
  fundraiseConfig: IFundraiseConfig | undefined;
  liquidiseConfig?: ILiquidiseConfig;
  shareCertificateConfig?: IShareCertificateConfig;
  setShareCertificateConfig: (data: IShareCertificateConfig | undefined) => void;
  notesCertificateConfig?: INotesCertificateConfig;
  setNotesCertificateConfig: (data: INotesCertificateConfig | undefined) => void;
  isSharesLockedForTrading: boolean;
  isOptionsLockedForTrading: boolean;
  refreshTradingData: () => void;
  companyContactEmail: string;
  officeholders: { DIR: IOfficeholder[]; SEC: IOfficeholder[] };
  getOfficeholderDetails: (id: string) => IOfficeholder | undefined;
  displayRequestBadge: boolean;
  setDisplayRequestBadge: (displayRequestBadge: boolean) => void;
  userCompanyRoles: IUserCompanyRoles;
  investorRequests: {
    pending: IInvestorsListData | undefined;
    declined: IInvestorsListData | undefined;
  };
  getInvestorRequests: (
    keyword?: string,
    page?: number,
    rowsPerPage?: number,
    sortBy?: string,
    sortOrder?: Order,
    status?: ShareholderStatus[],
  ) => Promise<void>;
  activeRequestsList: IInvestorsListData | undefined;
  setActiveRequestsList: (data: IInvestorsListData | undefined) => void;
}

export const CompanyContext = React.createContext<CompanyContextType>({
  companyId: "",
  companyName: "",
  setCompanyName: () => {},
  acn: "",
  setAcn: () => {},
  entityType: "",
  setEntityType: () => {},
  trusteeType: "",
  setTrusteeType: () => {},
  isAsicConnectionActivated: false,
  setAsicConnectionActivated: () => {},
  isLoadingCompanyInfo: false,
  setIsLoadingCompanyInfo: () => {},
  selectedSubscriptionPlan: "",
  setSelectedSubscriptionPlan: () => {},
  selectedTier: "tier4",
  setSelectedTier: () => {},
  displayName: "",
  setDisplayName: () => {},
  displayLogo: "",
  setDisplayLogo: () => {},
  refreshCompanyData: (newCompanyId?: string) => {},
  loadCompanyInfo: async () => {},
  companyType: "",
  activeShareholders: 0,
  setActiveShareholders: () => {},
  features: {},
  dateOfRegistration: null,
  isAgentAuthorized: false,
  fundraiseConfig: undefined,
  liquidiseConfig: undefined,
  shareCertificateConfig: defaultShareCertificateConfig,
  setShareCertificateConfig: () => {},
  notesCertificateConfig: defaultNotesCertificateConfig,
  setNotesCertificateConfig: () => {},
  isSharesLockedForTrading: false,
  isOptionsLockedForTrading: false,
  refreshTradingData: () => {},
  companyContactEmail: "",
  officeholders: { DIR: [], SEC: [] },
  getOfficeholderDetails: () => undefined,
  displayRequestBadge: false,
  setDisplayRequestBadge: () => {},
  userCompanyRoles: getMockUserCompanyRoles(),
  investorRequests: { pending: undefined, declined: undefined },
  getInvestorRequests: async () => {},
  activeRequestsList: undefined,
  setActiveRequestsList: () => {},
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useCompanyContext = () => React.useContext(CompanyContext);

export const CompanyContextProvider: React.FC = ({ children }) => {
  const location = useLocation();
  const [companyName, setCompanyName] = React.useState("");
  const [acn, setAcn] = React.useState("");
  const [entityType, setEntityType] = React.useState<string>("");
  const [trusteeType, setTrusteeType] = React.useState<string>("");
  const [displayName, setDisplayName] = React.useState<string>("");
  const [displayLogo, setDisplayLogo] = React.useState<string>("");
  const [isAsicConnectionActivated, setAsicConnectionActivated] = React.useState<boolean>(false);
  const [selectedSubscriptionPlan, setSelectedSubscriptionPlan] = React.useState<string>("");
  const [selectedTier, setSelectedTier] = React.useState<Tier>("tier4");
  const [activeShareholders, setActiveShareholders] = React.useState<number>(0);
  const [features, setFeatures] = React.useState<{ [key: string]: boolean }>({});
  const [companyType, setCompanyType] = React.useState("");
  const [dateOfRegistration, setDateOfRegistration] = React.useState<Date | null>(null);
  const [isAgentAuthorized, setIsAgentAuthorized] = React.useState(false);
  const [isLoadingCompanyInfo, setIsLoadingCompanyInfo] = React.useState(false);
  const [fundraiseConfig, setFundraiseConfig] = React.useState<IFundraiseConfig | undefined>(undefined);
  const [liquidiseConfig, setLiquidiseConfig] = React.useState<ILiquidiseConfig | undefined>(undefined);
  const [shareCertificateConfig, setShareCertificateConfig] = React.useState<IShareCertificateConfig | undefined>(
    undefined,
  );
  const [notesCertificateConfig, setNotesCertificateConfig] = React.useState<INotesCertificateConfig | undefined>(
    undefined,
  );
  const [companyContactEmail, setCompanyContactEmail] = React.useState<string>("");

  const [isLoadingTradingData, setIsLoadingTradingData] = React.useState<boolean>(true);
  const [isTradingWindowActive, setIsTradingWindowActive] = React.useState<boolean>(false);
  const [officeholders, setOfficeholders] = React.useState<{ DIR: IOfficeholder[]; SEC: IOfficeholder[] }>({
    DIR: [],
    SEC: [],
  });

  const [displayRequestBadge, setDisplayRequestBadge] = useState<boolean>(false);
  const [userCompanyRoles, setUserCompanyRoles] = useState<IUserCompanyRoles>(getMockUserCompanyRoles());
  const [investorRequests, setInvestorRequests] = useState<{
    pending: IInvestorsListData;
    declined: IInvestorsListData;
  }>({ pending: { count: 0, investors: [] }, declined: { count: 0, investors: [] } });
  const [activeRequestsList, setActiveRequestsList] = useState<IInvestorsListData | undefined>(undefined);

  const { enqueueSnackbar } = useSnackbar();
  const { currentUser } = useAuthContext();
  const baseUrl = getBaseServerUrl();

  const matchCompanyId = location.pathname.match(/companies-.*?(?=(?:\/))/);
  let companyId = matchCompanyId ? matchCompanyId[0] : "";

  const getUserCompanyRoles = useCallback((): IUserCompanyRoles => {
    const companyRoles = currentUser?.rolesByCompany?.find((role: IUserRolesByCompany) => role.companyId === companyId);
    const checkRole = (role: Roles) => (companyRoles ? companyRoles.roles.includes(role) : false);

    return {
      isSuperAdmin: checkRole(Roles.SUPER_ADMIN) && !currentUser?.isInvestor,
      isAdmin: (checkRole(Roles.ADMIN) || checkRole(Roles.SUPER_ADMIN)) && !currentUser?.isInvestor,
      isInvestor: checkRole(Roles.SHAREHOLDER) && currentUser?.isInvestor,
      isExternalInvestor: checkRole(Roles.EXTERNAL_INVESTOR) && currentUser?.isInvestor,
      isConcierge: checkRole(Roles.CONCIERGE),
      isNominee: checkRole(Roles.NOMINEE),
      isDirector: checkRole(Roles.DIRECTOR),
      isCompanySecretary: checkRole(Roles.COMPANY_SECRETARY),
    };
  }, [companyId, currentUser?.rolesByCompany, currentUser?.isInvestor]);

  const loadCompanyInfo = async () => {
    setIsLoadingCompanyInfo(true);
    if (companyId) {
      try {
        const result = await companyService.getCompanyInfo(companyId);
        const roles = getUserCompanyRoles();
        setUserCompanyRoles(roles);

        let officeholdersResult: { DIR: IOfficeholder[]; SEC: IOfficeholder[] } = { DIR: [], SEC: [] };
        if (roles.isAdmin) officeholdersResult = (await companyService.getOfficeholders(companyId))?.payload;

        const company = result.payload;
        setCompanyName(company.name);
        setAcn(company.acn);
        setDisplayLogo(company.displayLogo);
        setDisplayName(company.displayName);
        setAsicConnectionActivated(company.isAsicConnectionActivated);
        setSelectedSubscriptionPlan(company.stripeData.selectedSubscriptionPlan);
        setSelectedTier(
          (company.stripeData.selectedTier ??
            (company.stripeData.selectedSubscriptionPlan === "plan1" ? "tier1" : "tier4")) as Tier,
        );
        setCompanyType(company.companyType);
        setActiveShareholders(company.activeShareholders ?? 0);
        setFeatures(company.features || {});
        setDateOfRegistration(company.dateOfRegistration || null);
        setIsAgentAuthorized(company.isAgentAuthorized || false);
        setFundraiseConfig(company.fundraiseConfig ?? undefined);
        setLiquidiseConfig(company.liquidiseConfig ?? undefined);
        setShareCertificateConfig(company.shareCertificateConfig ?? undefined);
        setNotesCertificateConfig(company.notesCertificateConfig ?? undefined);
        setCompanyContactEmail(company.companyContactEmail ?? company.primaryAdminEmail ?? "");
        setOfficeholders({ ...officeholdersResult });
      } catch (error) {
        if (currentUser) {
          enqueueSnackbar(errorMessage(error, "An error occurred retrieving your information."), {
            variant: "error",
          });
        }
      }
    }
    setIsLoadingCompanyInfo(false);
  };

  const getCompanyInfo = useCallback(loadCompanyInfo, [companyId, currentUser, enqueueSnackbar, getUserCompanyRoles]);

  const getOfficeholderDetails = useCallback(
    (id: string) => mergeOfficeHolders(officeholders).find((officeholder) => officeholder._id === id),
    [officeholders],
  );

  const getTradingWindow = useCallback(getTradingWindowData, [companyId, liquidiseConfig?.auctionId, currentUser]);
  React.useEffect(() => {
    getCompanyInfo();
    getTradingWindow();
  }, [getCompanyInfo, getTradingWindow]);

  async function getTradingWindowData() {
    if (liquidiseConfig?.auctionId && currentUser && companyId) {
      try {
        const auctionDTO = (await liquidiseService.getTradingWindow(companyId, liquidiseConfig?.auctionId, currentUser))
          .payload;
        setIsTradingWindowActive(auctionDTO?.activated && !["Closed", "Cancelled"].includes(auctionDTO?.phase));
        setIsLoadingTradingData(false);
      } catch {
        setIsLoadingTradingData(true);
      }
    } else {
      setIsLoadingTradingData(false);
    }
  }

  const refreshTradingData = () => {
    setIsLoadingTradingData(true);
    getTradingWindow();
  };

  const refreshCompanyData = (newCompanyId?: string) => {
    companyId = newCompanyId ?? companyId;
    getCompanyInfo();
  };

  const getInvestorRequests = useCallback(
    async (
      keyword = "",
      page = 1,
      rowsPerPage = DEFAULT_PAGE_SIZE,
      sortBy = "createdAt",
      sortOrder: Order = DEFAULT_SORT_ORDER,
      status: ShareholderStatus[] = [ShareholderStatus.PENDING, ShareholderStatus.DECLINED],
    ) => {
      try {
        if (!companyId || !userCompanyRoles.isAdmin) return;
        if (status.includes(ShareholderStatus.ACCEPTED)) throw new Error("Investor request status is invalid.");
        setDisplayRequestBadge(false);

        const declinedInvestorRequest = await companyService.getAllInvestors(
          companyId,
          keyword,
          page,
          rowsPerPage,
          sortBy,
          sortOrder,
          [ShareholderStatus.DECLINED],
        );

        const pendingInvestorRequest = await companyService.getAllInvestors(
          companyId,
          keyword,
          page,
          rowsPerPage,
          sortBy,
          sortOrder,
          [ShareholderStatus.PENDING],
        );

        if (pendingInvestorRequest.payload.count > 0) {
          setDisplayRequestBadge(true);
        }

        /**
         * status can be either pending or declined or both or neither
         * only update setInvestorRequests to include either declinedInvestorRequest or pendingInvestorRequest based on that
         */
        setInvestorRequests({
          pending: status.includes(ShareholderStatus.PENDING)
            ? pendingInvestorRequest.payload
            : { count: pendingInvestorRequest.payload.count, investors: [] },
          declined: status.includes(ShareholderStatus.DECLINED)
            ? declinedInvestorRequest.payload
            : { count: declinedInvestorRequest.payload.count, investors: [] },
        });
      } catch (error) {
        enqueueSnackbar(errorMessage(error, "An error occurred retrieving investor requests."), {
          variant: "error",
        });
      }
    },
    [companyId, enqueueSnackbar, userCompanyRoles],
  );

  useEffect(() => {
    getInvestorRequests();
  }, [getInvestorRequests]);

  useEffect(() => {
    if (baseUrl && userCompanyRoles.isAdmin) {
      const socket = initialiseSocket(userCompanyRoles.isAdmin, companyId);

      if (socket) {
        socket.on("investorRequest", (data) => {
          try {
            getInvestorRequests();
          } catch (error) {
            socket.emit("clientError", { error: JSON.stringify(error) });
          }
        });

        return () => socket.disconnect();
      }
    }
    return () => {};
  }, [baseUrl, companyId, getInvestorRequests, userCompanyRoles.isAdmin]);

  return (
    <CompanyContext.Provider
      value={{
        companyId,
        companyName,
        setCompanyName,
        acn,
        setAcn,
        entityType,
        setEntityType,
        trusteeType,
        setTrusteeType,
        isAsicConnectionActivated,
        setAsicConnectionActivated,
        selectedSubscriptionPlan,
        setSelectedSubscriptionPlan,
        displayName,
        setDisplayName,
        displayLogo,
        setDisplayLogo,
        refreshCompanyData,
        loadCompanyInfo,
        companyType,
        selectedTier,
        setSelectedTier,
        activeShareholders,
        setActiveShareholders,
        features,
        dateOfRegistration,
        isAgentAuthorized,
        fundraiseConfig,
        liquidiseConfig,
        shareCertificateConfig,
        setShareCertificateConfig,
        notesCertificateConfig,
        setNotesCertificateConfig,
        isLoadingCompanyInfo,
        setIsLoadingCompanyInfo,
        isSharesLockedForTrading: !isLoadingTradingData && isTradingWindowActive,
        isOptionsLockedForTrading: !isLoadingTradingData && isTradingWindowActive,
        refreshTradingData,
        companyContactEmail,
        officeholders,
        getOfficeholderDetails,
        displayRequestBadge,
        setDisplayRequestBadge,
        userCompanyRoles,
        investorRequests,
        getInvestorRequests,
        activeRequestsList,
        setActiveRequestsList,
      }}
    >
      {children}
    </CompanyContext.Provider>
  );
};
